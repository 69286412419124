@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  background: #000;
}

.body-size {
  max-width: 1440px;
}

.scroller {
  scrollbar-width: thin;
  scrollbar-color: #394045 transparent;
}
